/** Remains: Camera video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class RemainsPage4 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[4];
    preloadData = globalState.assets[5];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //set volume for base track
    globalState.baseAudioRef.updateVolume(2, 100);
    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.text2,
      globalState.cdnUrl + preloadData.bgVideoPoster,
    ], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
      //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop, 0);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  

  render() {

    return (

      <>

        <link rel="prefetch" href="/remains/5" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
        )}
        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/remains/5" prevLocation="/remains/3"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Remains" />


          <div className="text-wrapper">
            <div className="text text--right-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "6vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="When Pine Point was shut down, Richard moved to Victoria to become a bouncer, but it turns out he wasn’t mean enough. The fighter turned out to be more of a protector. He ended up working with severely handicapped kids, including kids with MS. Richard’s limbs are useless to him now. He builds the Pine Point website using only his voice. It’s excruciating to watch – slowly pushing pixels up an ever-steepening hill. I suppose we all want a chance to edit our story, to keep the best stuff on top, bury the rest, decide how we’ll be remembered by others." />
            </div>
          </div>

          
          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={false} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }


        </div>
        )}
      </>
    );
  }


};

export default RemainsPage4
